import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import closingSheetService from '../../../services/erp/closingSheetService';
import ClosingSheetForm from './ClosingSheetForm';
import expenseTypeService from '../../../services/erp/expenseTypeService';
import taxTypeService from '../../../services/erp/taxTypeService';
import { subHours, subDays } from 'date-fns';
import moment from 'moment';
import workItemService from '../../../services/erp/workItemService';

const ClosingSheetEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const user = useSelector((state: any) => state.user.userInfo);
  const [expenseTypes, setExpenseTypes] = useState<any[]>([
    { id: '', name: '', description: '' },
  ]);
  const [entity, setEntity] = useState<any>({
    date: '',
    startTime: '',
    endTime: '',
    comment: '',
    openBalance: '',
    cash: '',
    creditCard: '',
    transfer: '',
    expenses: [],
    workItems: [],
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    expenseTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setExpenseTypes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    closingSheetService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
        if (!response.result.isClosed) {
          const now = new Date();

          setEntity({
            ...response.result,
            endTime: now,
          });
        }
      }
    });
  }, [params.id]);

  useEffect(() => {
    if (entity.date && entity.startTime && entity.endTime && !entity.isClosed) {
      let startTime = entity.startTime;
      let endTime = entity.endTime;

      workItemService
        .getUserCompletedWorkItems(user.userId, startTime, endTime)
        .then((res) => {
          if (res.hasError) {
            enqueueSnackbar(res.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setEntity({
              ...entity,
              cash: res.result.cash,
              creditCard: res.result.creditCard,
              transfer: res.result.transfer,
              workItems: res.result.workItems,
            });
          }
        });
    }
  }, [entity.date, entity.startTime, entity.endTime]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    if (entity.isClosed) {
      closingSheetService
        .update(entity)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            setErrors(response.errorMessages);
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres mentés!', {
              variant: 'success',
            });

            navigate(-1);
          }
        })
        .finally(() => {
          dispatch({ type: 'HIDE' });
          setSubmitting(false);
        });
    } else {
      closingSheetService
        .close(entity)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            setErrors(response.errorMessages);
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Sikeres mentés!', {
              variant: 'success',
            });

            navigate(-1);
          }
        })
        .finally(() => {
          dispatch({ type: 'HIDE' });
          dispatch({ type: 'SET_HAS_CLOSING_SHEET', payload: false });
          setSubmitting(false);
        });
    }
  };

  return (
    <ClosingSheetForm
      entity={entity}
      readonly={
        !checkPermission(['ClosingSheetEdit']) ||
        (entity.isClosed
          ? new Date().toDateString() !==
            moment.utc(entity.date).local().toDate().toDateString()
          : false)
      }
      onSubmit={onSubmit}
      taxes={taxes}
      handleCancel={() => navigate(-1)}
      errors={errors}
      expenseTypes={expenseTypes}
    />
  );
};

export default ClosingSheetEdit;
