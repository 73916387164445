import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OfferForm from './OfferForm';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import offerService from '../../../services/erp/offerService';
import serviceService from '../../../services/erp/serviceService';
import clientService from '../../../services/crm/clientService';
import itemPriceService from '../../../services/pricing/itemPriceService';
import priceCategoryService from '../../../services/pricing/priceCategoryService';
import { reset } from '../../../stateManagement/actions/offerActions';
import { offerStatus } from '../../../types/OfferStatus';
import { setForm } from '../../../stateManagement/actions/offerActions';
import taxTypeService from '../../../services/erp/taxTypeService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import documentBlockService from '../../../services/billing/documentBlockService';
import { DocumentBlockTypes } from '../../../types/DocumentBlockTypes';
import moment from 'moment';
import { replaceItemPackage } from '../../../stateManagement/actions/salesActions';
import { Currency } from '../../../types/Currency';
import { DocumentLanguage } from '../../../types/DocumentLanguage';

const OfferEdit = (props: any) => {
  const { onSave = null, handleCancel = null } = props;
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const { checkPermission } = useCheckPermission();

  const [services, setServices] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [listPrices, setListPrices] = useState<any[]>([]);
  const [documentBlocks, setDocumentBlocks] = useState<any[]>([]);
  const [priceCategories, setPriceCategories] = useState<any[]>([]);
  const [conversionRateState, setConversionRateState] = useState<number>(1);

  const [priceCategoryId, setPriceCategoryId] = useState<number>(0);
  const { form, offerInProgress } = useSelector<any>(
    (state) => state.offer
  ) as any;

  const [now] = useState(new Date());

  const [entity, setEntity] = useState<any>(
    offerInProgress
      ? form
      : {
          expireAt: moment(now).add(30, 'days').toDate(),
          clientId: null,
          companyId: null,
          clientName: '',
          documentBlockId: null,
          offerNumber: '',
          planned: null,
          language: DocumentLanguage.Hu,
          conditions: '',
          guarantee: '',
          currency: Currency.HUF,
          conversionRate: 1,
          remark: '',
          selectedServices: [],
          selectedItems: [],
          itemPackages: [],
          workAddressId: null,
          billingAddressId: null,
          priceCategoryId: null,
          taskDescription: '',
          status: offerStatus.draft,
          task: '',
          deadLine: '',
          representativeName: { firstName: '', lastName: '' },
          representativePhone: '',
          createdAt: now,
        }
  );

  useEffect(() => {
    documentBlockService.list(DocumentBlockTypes.Offer).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setDocumentBlocks(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    priceCategoryService.list(null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setPriceCategories(response.records);
      }
    });
  }, []);

  useEffect(() => {
    if (priceCategoryId > 0) {
      itemPriceService
        .listListPrices(null, priceCategoryId, true)
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            setListPrices(response.records);
          }
        });
    }
  }, [priceCategoryId]);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    clientService.list(false, null).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
      }
      dispatch({ type: 'HIDE' });
    });
  }, []);

  useEffect(() => {
    serviceService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setServices(response.records);
      }
    });
  }, []);
  useEffect(() => {
    if (!form.id) {
      offerService.get(params.id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setEntity(response.result);
          setConversionRateState(response.result.conversionRate);
        }
      });
    }
  }, [params.id]);

  const handleLoadItemPrices = (
    warehouseId: number,
    priceCategoryId: number,
    setFieldValue: any
  ) => {
    itemPriceService
      .listListPrices(warehouseId, priceCategoryId, true)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setListPrices(response.records);
          if (form.selectedItems && form?.selectedItems.length > 0) {
            var clonedSelectedItems = [...form.selectedItems];
            var updatedSelectedItems = clonedSelectedItems.map(
              (selectedItem) => {
                var matchedItem = response.records.find(
                  (item) => item.itemId === selectedItem.itemId
                );
                if (matchedItem) {
                  return {
                    ...selectedItem,
                    sellPriceGross: matchedItem.gross,
                    sellPriceNet: matchedItem.net,
                  };
                }
                return selectedItem;
              }
            );
            setFieldValue('selectedItems', [...updatedSelectedItems]);
          }
          if (form.itemPackages && form.itemPackages.length > 0) {
            var clonedItemPackages = [...form.itemPackages];

            let newitemPackages = clonedItemPackages.map((itemPackage) => {
              let sellPriceGross = 0;
              let sellPriceNet = 0;
              let newItems = itemPackage.items?.map((item) => {
                let found = response.records.find(
                  (y) => y.itemId === item.itemId
                );

                sellPriceGross += found?.gross ?? 0 * item?.amount ?? 0;
                sellPriceNet += found?.net ?? 0 * item?.amount ?? 0;
                return {
                  ...item,
                  sellPriceNet: found?.net ?? 0,
                  sellPriceGross: found?.gross ?? 0,
                };
              });
              itemPackage.services?.map((service) => {
                sellPriceGross +=
                  service?.servicePriceGross ?? 0 * service?.amount ?? 0;
                sellPriceNet +=
                  service?.servicePriceNet ?? 0 * service?.amount ?? 0;
              });
              return {
                ...itemPackage,
                items: newItems,
                sellPriceGross: sellPriceGross,
                sellPriceNet: sellPriceNet,
              };
            });
            setFieldValue('itemPackages', [...newitemPackages]);
          }
        }
      });
  };

  const onSubmit = (entity: any, setSubmitting: any, representative: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    var isDenied = entity.status === offerStatus.denied;
    var newEntity = isDenied
      ? { ...entity, status: offerStatus.draft }
      : { ...entity };
    if (representative) {
      clientService.updateRepresentative(representative).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      });
    }

    offerService
      .update(newEntity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          dispatch(reset());
          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <OfferForm
      entity={entity}
      readonly={
        !checkPermission(['OfferEdit']) ||
        (entity.status !== offerStatus.draft &&
          entity.status !== offerStatus.denied)
      }
      onSubmit={onSubmit}
      errors={errors}
      clients={clients}
      setClients={setClients}
      conversionRateState={conversionRateState}
      setConversionRateState={setConversionRateState}
      services={services}
      listPrices={listPrices}
      taxes={taxes}
      priceCategories={priceCategories}
      priceCategoryId={priceCategoryId}
      documentBlocks={documentBlocks}
      loadItemPrices={handleLoadItemPrices}
      setPriceCategoryId={setPriceCategoryId}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default OfferEdit;
