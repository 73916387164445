import {
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  Autocomplete,
  Tooltip,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { ClientTypes } from '../../../types/ClientTypes';
import Divider from '@mui/material/Divider';
import SelectClient from '../../crm/clients/SelectClient';
import ChimneyControlDialog from './ChimneyControlDialog';
import { useState } from 'react';
import VerticalTable from './VerticalTable';
import ChimneyWorkDetails from './ChimneyWorkDetails';
import OtherInformation from './OtherInformation';
import { Delete } from '@mui/icons-material';
import { ModuleSettingsType } from '../../../types/ModuleSettingsType';
import useModuleSettings from '../../../hooks/useModuleSettings';
import { useSelector } from 'react-redux';
import useConfirmDialog from '../../../hooks/useConfirmDialog';
import {
  translateChimneySweepingCertOrderType,
  translateDamageScaleName,
} from '../../../utils/nameFormatters';
import { DamageScale } from '../../../types/DamageScale';
import { ChimneySweepingCertOrderType } from '../../../types/ChimneySweepingCertOrderType';

export const defaultChimneyControl = {
  groupNumber: '',
  chimneySweepingCertificateId: 0,
  airFollowUp: 'I',
  cleaningRequirement: 'I',
  freeCrossSectionOfPassage: 'I',
  externalCondition: 'I',
  accessoryCondition: 'I',
  connectionElementCondition: 'I',
  other: '-',
  checkableDetachableConnectionsCount: '1/1',
  unDetachableConnectionsCount: '',
  inspectionFrequency: '1 év',
  connectionElementLength: '',
  dangerous: '',
  cOMeasuringNeccessary: 'I',
  chimneyType: '',
  performanceCategory: 'B',
  isTechnicalOverView: '',
  yearOfLastTechnicalOverView: '',
  chimneyUseCase: 'C',
  cOContent1: '',
  cOContent2: '',
  cOContent3: '',
  chimneyExternalCondition1: 'I',
  chimneyExternalCondition2: '',
  chimneyExternalCondition3: '',
  isInUse: true,
  hasBeenUsed: true,
  isBanned: false,
  usingSinceDate: '',
  chimneyId: 0,
};

const ChimneySweepingCertificateForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    setRepresentative,
    representative,
    handleClientSelected,
    onAddressAdded,
    onClientAdded,
    chimneyTypes,
    useCases,
    performanceCategories,
    errorCodes,
    users,
    onWorkAddressSelected = () => {},
    chimneys,
    onEmployeeSelected = () => {},
    isDialog,
    coSensors,
  } = props;

  const [chimneyDialog, setChimneyDialog] = useState<any>({
    open: false,
    chimneyControl: defaultChimneyControl,
  });
  const paymentTypes = ['Készpénz', 'Bankkártya', 'Átutalás'];
  const [now, setNow] = useState(new Date());
  const [currentNegativeId, setCurrentNegativeId] = useState(-1);
  const userId = useSelector((state: any) => state.user.userInfo.userId);
  const { ConfirmDialog, setConfirmParams } = useConfirmDialog();
  const moduleSettings = useModuleSettings({
    type: ModuleSettingsType.ChimneySweepingCertificateInvoice,
    userId: userId,
  });
  const getData = (values: any) => {
    const verticalTable = [];

    values.chimneyControls.forEach((chimneyControl) => {
      const rowData = {
        chimneyId: chimneyControl.chimneyId,
        'Csoport száma': chimneyControl.groupNumber,
        Jele: chimneyControl.chimneyType,
        'Ellenőrzés/bontható bekötés száma':
          chimneyControl.checkableDetachableConnectionsCount,
        'K,N járat/összekötő elem hossz':
          chimneyControl.connectionElementLength,
        'Használat célja': chimneyControl.chimneyUseCase,
        'Teljesítmény kategória': chimneyControl.performanceCategory,
        'Szénmonoxid mérés szükséges(C)': chimneyControl.cOMeasuringNeccessary,
        'Tüzelőberendezés külső állapota 1':
          chimneyControl.chimneyExternalCondition1,
        'Tüzelőberendezés külső állapota 2':
          chimneyControl.chimneyExternalCondition2,
        'Tüzelőberendezés külső állapota 3':
          chimneyControl.chimneyExternalCondition3,
        'Levegő utánpótlás': chimneyControl.airFollowUp,
        'Tisztítás feltételei': chimneyControl.cleaningRequirement,
        'Járat szabad keresztmetszete':
          chimneyControl.freeCrossSectionOfPassage,
        'Külső állapota': chimneyControl.externalCondition,
        'Tartozékainak állapota': chimneyControl.accessoryCondition,
        'Összekötő elem állapota': chimneyControl.connectionElementCondition,
        Egyéb: chimneyControl.other,
        'Utolsó műszaki felülvizsgálat éve':
          chimneyControl.yearOfLastTechnicalOverView
            ? new Date(
                chimneyControl.yearOfLastTechnicalOverView
              ).toLocaleDateString()
            : '',
        'Műszaki felülvizsgálat':
          chimneyControl.isTechnicalOverView !== ''
            ? chimneyControl.isTechnicalOverView
              ? 'Igen'
              : 'Nem'
            : '-',
        'Szén-monoxid tartalom (ppm) 1': chimneyControl.coContent1,
        'Szén-monoxid tartalom (ppm) 2': chimneyControl.coContent2,
        'Szén-monoxid tartalom (ppm) 3': chimneyControl.coContent3,
        'Élet-és vagyonvédelmi szempontból veszélyes':
          chimneyControl.dangerous != ''
            ? chimneyControl.dangerous
              ? 'Igen'
              : 'Nem'
            : '-',
        'Használatban van':
          chimneyControl.isInUse != ''
            ? chimneyControl.isInUse
              ? 'Igen'
              : 'Nem'
            : '-',
        'Volt már használva':
          chimneyControl.hasBeenUsed != ''
            ? chimneyControl.hasBeenUsed
              ? 'Igen'
              : 'Nem'
            : '-',
        'Tiltva van':
          chimneyControl.isBanned != ''
            ? chimneyControl.isBanned
              ? 'Igen'
              : 'Nem'
            : '-',
        'Első használat': chimneyControl.usingSinceDate
          ? new Date(chimneyControl.usingSinceDate).toLocaleDateString()
          : '',
      };

      verticalTable.push(rowData);
    });

    return verticalTable;
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.clientId) {
          errors.clientId = 'Required';
        }
        if (!values.serviceProviderId) {
          errors.serviceProviderId = 'Required';
        }
        if (!values.documentNumber) {
          errors.documentNumber = 'Required';
        }
        if (!(values.workAddressId > 0)) {
          errors.workAddressId = 'Required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (
          values.carbonMonoxideSensors.length > 0 &&
          values.carbonMonoxideSensors.some(
            (x) => x.clientId !== values.clientId
          )
        ) {
          setConfirmParams({
            open: true,
            name: 'Van olyan érzékelő a kosárba amely nem a kiválasztott ügyfélhez tartozik. Ha elfogadja az érzékelő az új ügyfélhez lesz rendelve.',
            onConfirm: async () => {
              onSubmit(values, setSubmitting);
            },
          });
          setSubmitting(false);
        } else {
          onSubmit(values, setSubmitting);
        }
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        setSubmitting,
        validateForm,
        resetForm,
        handleChange,
        errors: validationErrors,
      }) => {
        const firstSectionDone =
          values.clientId > 0 &&
          values.documentNumber &&
          values.serviceProviderId > 0 &&
          values.workAddressId > 0;
        return (
          <Form>
            <Paper>
              <Grid container justifyContent="left" spacing={2} p={5}>
                <h2>
                  Tanúsítvány {values.id > 0 ? 'megtekintése' : 'létrehozása'}
                </h2>
                <Grid xs={12} item pt={5}>
                  <Box color="red">
                    {errors.map((error) => (
                      <li>{error}</li>
                    ))}
                  </Box>
                </Grid>
                <Grid
                  container
                  p={2}
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      disablePortal
                      id="companyId"
                      disabled={readonly}
                      value={values.serviceProviderId ?? ''}
                      onChange={(event, value) => {
                        setFieldValue('serviceProviderId', value);
                      }}
                      getOptionLabel={(option) =>
                        clients?.find((g) => g.id === option)?.companyName ?? ''
                      }
                      options={clients
                        ?.filter(
                          (x) => x.clientType === ClientTypes.Distributor
                        )
                        ?.map((g) => g.id)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          label="Kéményseprő-ipari szolgáltató"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      value={values.documentNumber ?? ''}
                      onChange={handleChange}
                      label="Dokumentum száma"
                      name="documentNumber"
                      required
                      disabled={readonly}
                      fullWidth
                      autoFocus
                      type="number"
                      error={!!validationErrors.documentNumber}
                      helperText={
                        !!touched.documentNumber &&
                        !!validationErrors.documentNumber &&
                        (validationErrors.documentNumber as string)
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
                      Ügyfél
                    </Divider>
                    <SelectClient
                      values={values}
                      setFieldValue={setFieldValue}
                      readonly={readonly}
                      clients={clients}
                      setRepresentative={setRepresentative}
                      representative={representative}
                      onClientChange={handleClientSelected}
                      onClientAdded={onClientAdded}
                      onAddressAdded={onAddressAdded}
                      onWorkAddressSelected={(id: any) => {
                        onWorkAddressSelected(id);
                      }}
                      hideRepresentative={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
                    Számla
                  </Divider>
                </Grid>
                <Grid item xs={12}>
                  {' '}
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      disablePortal
                      id="paymentMethod"
                      disabled={readonly}
                      value={values?.paymentMethod}
                      onChange={(event, value) => {
                        setFieldValue('paymentMethod', value);
                        if (value === 'Átutalás') {
                          setFieldValue('isPaid', false);
                        } else {
                          setFieldValue('isPaid', true);
                        }
                      }}
                      options={paymentTypes?.map((g) => g)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          error={
                            !!touched.paymentType &&
                            !!validationErrors.paymentType
                          }
                          helperText={
                            !!touched.paymentType &&
                            !!validationErrors.paymentType &&
                            (validationErrors.paymentType as string)
                          }
                          fullWidth
                          label="Fizetési mód"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={'auto'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isPaid}
                          onChange={(e, checked) =>
                            setFieldValue('isPaid', checked)
                          }
                          color="primary"
                        />
                      }
                      label="Kifizetve"
                    />
                  </Grid>
                  <Grid item xs={12} md={'auto'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.hasDeliveryFee}
                          onChange={(e, checked) =>
                            setFieldValue('hasDeliveryFee', checked)
                          }
                          color="primary"
                        />
                      }
                      label="Kiszállási díj"
                    />
                  </Grid>
                  <Grid item xs={12} md={'auto'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isOrdered}
                          onChange={(e, checked) =>
                            setFieldValue('isOrdered', checked)
                          }
                          color="primary"
                        />
                      }
                      label="Megrendelve"
                    />
                  </Grid>
                  <Grid item xs={12} md={3} pt={1}>
                    <Autocomplete
                      disablePortal
                      value={values.orderType}
                      disabled={readonly}
                      onChange={(event, value) => {
                        setFieldValue('orderType', value);
                      }}
                      getOptionLabel={(option) => {
                        return translateChimneySweepingCertOrderType(
                          option as ChimneySweepingCertOrderType
                        );
                      }}
                      options={
                        Object.values(ChimneySweepingCertOrderType).filter(
                          (x) => Number.isFinite(x)
                        ) ?? []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Megrendelés típusa"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {!firstSectionDone && (
                  <Grid item xs={12}>
                    <Divider
                      style={{ color: 'red' }}
                      textAlign="left"
                      sx={{ mt: 2, mb: 2 }}
                    >
                      Továbblépéshez töltse ki az alapadatokat!
                    </Divider>
                  </Grid>
                )}

                <Grid item container spacing={3} xs={12}>
                  <Grid item>
                    <Tooltip title="Kémény hozzáadása">
                      <Button
                        color="primary"
                        disabled={readonly || !firstSectionDone}
                        onClick={() => {
                          setChimneyDialog((actual: any) => {
                            return {
                              open: true,
                              chimneyControl: {
                                ...defaultChimneyControl,
                                chimneyId:
                                  (values.chimneyControls.length + 1) * -1,
                                groupNumber: `${
                                  values.chimneyControls.length + 1
                                }`,
                              },
                            };
                          });
                        }}
                        variant="contained"
                      >
                        Kémény hozzáadása
                      </Button>
                    </Tooltip>
                  </Grid>
                  {chimneys?.length > 0 &&
                    values.chimneyControls.length === 0 && (
                      <Grid item>
                        <Tooltip title="Korábbi Kémények hozzáadása">
                          <Button
                            color="primary"
                            disabled={readonly || !firstSectionDone}
                            onClick={() => {
                              const toAdd = chimneys.map((x, i) => {
                                return {
                                  ...defaultChimneyControl,
                                  usingSinceDate: x.usingSinceDate,
                                  isBanned: x.isBanned,
                                  isInUse: x.isInUse,
                                  hasBeenUsed: x.hasBeenUsed,
                                  chimneyId: x.id,
                                  chimneyType: x.typeName,
                                  groupNumber: x.groupNumber ?? '',
                                  connectionElementLength:
                                    x.connectionElementLength ?? '',
                                };
                              });
                              setFieldValue('chimneyControls', [
                                ...values.chimneyControls,
                                ...toAdd,
                              ]);
                            }}
                            variant="contained"
                          >
                            Korábbi kémények betöltése
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={8} p={2}>
                  {values.chimneyControls.length > 0 ? (
                    <VerticalTable
                      data={getData(values)}
                      readonly={readonly || !firstSectionDone}
                      onDuplicationClicked={(row: any) => {
                        var control = values.chimneyControls.find(
                          (x) => x.chimneyId === row.chimneyId
                        );
                        setFieldValue('chimneyControls', [
                          ...values.chimneyControls,
                          {
                            ...control,
                            chimneyId: (values.chimneyControls.length + 1) * -1,
                            groupNumber: `${values.chimneyControls.length + 1}`,
                          },
                        ]);
                      }}
                      onCellClicked={(row: any) => {
                        var control = values.chimneyControls.find(
                          (x) => x.chimneyId === row.chimneyId
                        );

                        setChimneyDialog({
                          open: true,
                          chimneyControl: control,
                        });
                      }}
                      onDelete={(row: any) => {
                        var control = values.chimneyControls.find(
                          (x) => x.chimneyId === row.chimneyId
                        );

                        setFieldValue(
                          'chimneyControls',
                          values.chimneyControls.filter(
                            (x) => x.chimneyId !== control.chimneyId
                          )
                        );
                      }}
                    />
                  ) : (
                    <Box
                      style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        justifyContent: 'center', // add this line
                        height: '100%',
                      }}
                    >
                      Nincs megjeleníthető kémény.
                      <br /> Kémény hozzáadásához kattintson a 'Kémény
                      hozzáadása' gombra!
                    </Box>
                  )}
                </Grid>
                <Grid item container alignContent="flex-start" xs={4} p={2}>
                  <ChimneyWorkDetails
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    validationErrors={validationErrors}
                    handleChange={handleChange}
                    readonly={readonly || !firstSectionDone}
                    users={users}
                    onEmployeeSelected={onEmployeeSelected}
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <TextField
                    value={values.others ?? ''}
                    onChange={handleChange}
                    label="Egyéb"
                    name="others"
                    multiline
                    rows={4}
                    disabled={readonly || !firstSectionDone}
                    fullWidth
                    error={!!touched.others && !!validationErrors.others}
                    helperText={
                      !!touched.others &&
                      !!validationErrors.others &&
                      (validationErrors.others as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <OtherInformation
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    validationErrors={validationErrors}
                    handleChange={handleChange}
                    readonly={readonly || !firstSectionDone}
                    setCurrentNegativeId={setCurrentNegativeId}
                    currentNegativeId={currentNegativeId}
                    moduleSettings={moduleSettings}
                    coSensors={coSensors}
                    firstSectionDone={firstSectionDone}
                    now={now}
                  />
                </Grid>
                <Grid
                  container
                  item
                  pt={3}
                  justifyContent="left"
                  style={{
                    position: 'sticky',
                    bottom: 0,
                    background: 'white',
                    zIndex: 20,
                  }}
                >
                  {!readonly && (
                    <Grid item p={1}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !firstSectionDone}
                      >
                        Mentés
                      </Button>
                    </Grid>
                  )}
                  {!readonly && (
                    <Grid item p={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || !firstSectionDone}
                        onClick={() => {
                          validateForm().then((errors) => {
                            if (Object.keys(errors).length === 0) {
                              onSubmit(values, setSubmitting, true).then(() => {
                                const num = parseInt(values.documentNumber);
                                resetForm();
                                window.scrollTo(0, 0);
                                setFieldValue(
                                  'documentNumber',
                                  (num + 1).toString()
                                );
                              });
                            }
                          });
                        }}
                      >
                        Mentés és új tanúsítvány létrehozása
                      </Button>
                    </Grid>
                  )}
                  {!isDialog && (
                    <Grid item p={1}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancel}
                      >
                        Mégse
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <ChimneyControlDialog
                open={chimneyDialog.open}
                readonly={readonly}
                setChimneyControl={(chimneyControl: any) => {
                  setChimneyDialog((actual: any) => {
                    return { ...actual, chimneyControl: { ...chimneyControl } };
                  });
                }}
                chimneyControl={chimneyDialog.chimneyControl}
                handleClose={() =>
                  setChimneyDialog({
                    open: false,
                    chimneyControl: defaultChimneyControl,
                  })
                }
                chimneyTypes={chimneyTypes}
                onSave={(chimney) => {
                  var exist = values.chimneyControls.find(
                    (x) => x.chimneyId === chimney.chimneyId
                  );
                  if (!exist)
                    setFieldValue('chimneyControls', [
                      ...values.chimneyControls,
                      chimney,
                    ]);
                  else {
                    setFieldValue(
                      'chimneyControls',
                      values.chimneyControls.map((x) =>
                        x.groupNumber === chimney.groupNumber ? chimney : x
                      )
                    );
                  }
                }}
                useCases={useCases}
                performanceCategories={performanceCategories}
                errorCodes={errorCodes}
              />
              <ConfirmDialog
                actionName={
                  'Biztosan megváltoztatja a szén-monoxid érzékelők ügyfelét?'
                }
              />{' '}
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChimneySweepingCertificateForm;
