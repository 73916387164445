import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  parseJSON,
} from 'date-fns';
import moment from 'moment';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import userService from '../../services/authority/userService';
import clientService from '../../services/crm/clientService';
import priceCategoryService from '../../services/pricing/priceCategoryService';
import financeService from '../../services/statistics/financeService';
import brandService from '../../services/wms/brandService';
import itemGroupService from '../../services/wms/itemGroupService';
import itemService from '../../services/wms/itemService';
import warehouseService from '../../services/wms/warehouseService';
import { EntityStatuses } from '../../types/EntityStatuses';
import { formatCurrency } from '../../utils/valueFormatters';
import { EntityTypes } from '../../types/EntityTypes';
import EntityNavigator from '../../components/EntityNavigator';

const ItemsIncomeStatistics = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [priceCategories, setPriceCategories] = useState<any[]>([]);
  const [itemGroups, setItemGroups] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const titleDescriptor = useFunctionDescriptor('IncomeStatistics.title');
  const userId = useSelector((state: any) => state.user.userInfo.userId);
  const [totalNetPrice, setTotalNetPrice] = useState<number>(0);
  const [totalGrossPrice, setTotalGrossPrice] = useState<number>(0);
  const [selectedInterval, setSelectedInterval] = useState<string>('');

  const timeIntervalOptions = [
    { label: 'Napi', value: 'day' },
    { label: 'Heti', value: 'week' },
    { label: 'Havi', value: 'month' },
    { label: 'Negyedéves', value: 'quarter' },
    { label: 'Éves', value: 'year' },
  ];

  const [entity, setEntity] = useState<any>({
    employeeId: null,
    clientId: null,
    priceCategoryId: null,
    warehouseId: null,
    startDate: null,
    endDate: null,
    paymentType: null,
    itemId: null,
    brandId: null,
    itemGroupId: null,
  });

  useEffect(() => {
    if (!checkPermission(['ItemsIncomeQueryAll'])) {
      setEntity({ ...entity, employeeId: userId });
    }
  }, [userId]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let abort = new AbortController();

    clientService.list(false, null, abort.signal).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setClients(response.records);
      }
    });

    return () => {
      abort.abort();
    };
  }, []);
  useEffect(() => {
    warehouseService.list(userId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    priceCategoryService.list(userId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setPriceCategories(response.records);
      }
    });
  }, []);

  useEffect(() => {
    itemGroupService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setItemGroups(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let abort = new AbortController();

    itemService.list(EntityStatuses.Approved, abort.signal).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setItems(response.records);
      }
    });

    return () => {
      abort.abort();
    };
  }, []);

  useEffect(() => {
    brandService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setBrands(response.records);
      }
    });
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 100,
      valueFormatter: (params) => parseJSON(params.value).toLocaleDateString(),
    },
    { field: 'employeeName', headerName: 'Dolgozó', flex: 100 },
    { field: 'itemNumber', headerName: 'Cikkszám', flex: 100 },
    { field: 'itemName', headerName: 'Temék', flex: 100 },
    { field: 'brandName', headerName: 'Márka', flex: 100 },
    { field: 'itemGroupName', headerName: 'Temékcsoport', flex: 100 },
    { field: 'quantity', headerName: 'Darabszám', flex: 100 },
    {
      field: 'priceCategoryId',
      headerName: 'Árkategória',
      flex: 100,
      valueFormatter: (params) => {
        if (params.value === null) {
          return 'Nincs';
        }
        let priceCategory = priceCategories.find((g) => g.id === params.value);
        return priceCategory?.name;
      },
    },
    {
      field: 'warehouseId',
      headerName: 'Raktár',
      flex: 100,
      valueFormatter: (params) => {
        if (params.value === null) {
          return 'Nincs';
        }
        let warehouse = warehouses.find((g) => g.id === params.value);
        return warehouse?.name;
      },
    },
    {
      field: 'clientNameForQuickSearch',
      headerName: 'ÜgyfélKereső',
      flex: 100,
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
    },
    {
      field: 'priceNet',
      headerName: 'Nettó ár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'priceGross',
      headerName: 'Bruttó ár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'taxAmount',
      headerName: 'ÁFA',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    { field: 'paymentType', headerName: 'Fizetési mód', flex: 100 },
  ];

  const handleSearch = () => {
    let abort = new AbortController();

    dispatch({ type: 'SHOW_QUERY' });
    financeService
      .itemsIncomeQuery(entity, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setRows(response.records);
          const sumNetPrice = response.records.reduce(
            (total, row) => total + row.priceNet,
            0
          );
          setTotalNetPrice(sumNetPrice);
          const sumGrossPrice = response.records.reduce(
            (total, row) => total + row.priceGross,
            0
          );
          setTotalGrossPrice(sumGrossPrice);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });

    return () => {
      abort.abort();
    };
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Termék bevételi statisztika{titleDescriptor}</h2>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                id="selectedInterval"
                value={selectedInterval}
                onChange={(event, value) => {
                  setSelectedInterval(value);
                  const nowstart = moment(new Date()).startOf('day').toDate();
                  const nowend = moment(new Date()).endOf('day').toDate();
                  switch (value) {
                    case 'day':
                      setEntity({
                        ...entity,
                        startDate: nowstart,
                        endDate: nowend,
                      });
                      break;
                    case 'week':
                      const startOfWeekDate = startOfWeek(nowstart, {
                        weekStartsOn: 1,
                      });
                      const endOfWeekDate = endOfWeek(nowend, {
                        weekStartsOn: 1,
                      });
                      setEntity({
                        ...entity,
                        startDate: startOfWeekDate,
                        endDate: endOfWeekDate,
                      });
                      break;
                    case 'month':
                      const startOfMonthDate = startOfMonth(nowstart);
                      const endOfMonthDate = endOfMonth(nowend);
                      setEntity({
                        ...entity,
                        startDate: startOfMonthDate,
                        endDate: endOfMonthDate,
                      });
                      break;
                    case 'quarter':
                      const quarterStart = startOfQuarter(nowstart);
                      const quarterEnd = endOfQuarter(nowend);
                      setEntity({
                        ...entity,
                        startDate: quarterStart,
                        endDate: quarterEnd,
                      });
                      break;
                    case 'year':
                      const startOfYearDate = startOfYear(nowstart);
                      const endOfYearDate = endOfYear(nowend);
                      setEntity({
                        ...entity,
                        startDate: startOfYearDate,
                        endDate: endOfYearDate,
                      });
                      break;
                    default:
                      setEntity({ ...entity, startDate: null, endDate: null });
                      break;
                  }
                }}
                getOptionLabel={(option) => {
                  if (option === null) {
                    return 'Mindegy';
                  }
                  return (
                    timeIntervalOptions.find((o) => o.value === option)
                      ?.label ?? ''
                  );
                }}
                options={timeIntervalOptions.map((option) => option.value)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Időintervallum" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <DatePicker
                value={entity.startDate}
                onChange={(value) => setEntity({ ...entity, startDate: value })}
                label="Kezdő dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <DatePicker
                value={entity.endDate}
                onChange={(value) => {
                  if (value !== null) {
                    setEntity({
                      ...entity,
                      endDate: moment(value).endOf('day').toDate(),
                    });
                  } else {
                    setEntity({
                      ...entity,
                      endDate: null,
                    });
                  }
                }}
                label="Vég dátum"
                inputFormat="yyyy-MM-dd"
                componentsProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="itemId"
              value={entity.itemId}
              onChange={(event, value) => {
                setEntity({ ...entity, itemId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let item = items.find((g) => g.id === option);
                return `${item?.name} (${item?.ItemNumber ?? ''})`;
              }}
              options={[null, ...items.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Termék" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="brandId"
              value={entity.brandId}
              onChange={(event, value) => {
                setEntity({ ...entity, brandId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let brand = brands.find((g) => g.id === option);
                return `${brand?.name}`;
              }}
              options={[null, ...brands.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Márka" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="itemGroupId"
              value={entity.itemGroupId}
              onChange={(event, value) => {
                setEntity({ ...entity, itemGroupId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let itemGroup = itemGroups.find((g) => g.id === option);
                return `${itemGroup?.name}`;
              }}
              options={[null, ...itemGroups.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Termékcsoport" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="employeeId"
              disabled={!checkPermission(['ItemsIncomeQueryAll'])}
              value={entity.employeeId}
              onChange={(event, value) => {
                setEntity({ ...entity, employeeId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let user = users.find((g) => g.id === option);
                return `${user?.fullName} (${user?.email ?? ''})`;
              }}
              options={[null, ...users.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Dolgozó" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="clientId"
              disabled={!checkPermission(['ClientView'])}
              value={entity.clientId}
              onChange={(event, value) => {
                setEntity({ ...entity, clientId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                var found = clients.find((g) => g.id === option);
                if (found) {
                  return `${
                    found?.companyName === ''
                      ? found.name.fullName
                      : found.companyName
                  } (${found.taxNumber})}`;
                } else return 'Nem található';
              }}
              options={[null, ...clients.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Ügyfél" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="warehouseId"
              disabled={!checkPermission(['WarehouseView'])}
              value={entity.warehouseId}
              onChange={(event, value) => {
                setEntity({ ...entity, warehouseId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let warehouse = warehouses.find((g) => g.id === option);
                return warehouse?.name;
              }}
              options={[null, ...warehouses.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Raktár" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="priceCategoryId"
              disabled={!checkPermission(['PriceCategoryView'])}
              value={entity.priceCategoryId}
              onChange={(event, value) => {
                setEntity({ ...entity, priceCategoryId: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                let priceCategory = priceCategories.find(
                  (g) => g.id === option
                );
                return priceCategory?.name;
              }}
              options={[null, ...priceCategories.map((g) => g.id)]}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Árkategória" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disablePortal
              id="paymentType"
              value={entity.paymentType}
              onChange={(event, value) => {
                setEntity({ ...entity, paymentType: value });
              }}
              getOptionLabel={(option) => {
                if (option === null) {
                  return 'Mindegy';
                }
                return option;
              }}
              options={[null, 'Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét']}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Fizetési mód" />
              )}
            />
          </Grid>
          <Grid item xs={12} textAlign={'right'}>
            <Button variant="contained" onClick={() => handleSearch()}>
              Keresés
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" fontWeight={'bold'}>
              Összesen:
            </Typography>
          </Grid>
          <Grid item>
            {' '}
            <Typography variant="body1">
              {' '}
              Nettó: {formatCurrency(totalNetPrice)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Bruttó: {formatCurrency(totalGrossPrice)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={2}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ItemsIncomeStatistics'}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default ItemsIncomeStatistics;
