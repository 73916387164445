import { Delete, Info } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Dialog,
  DialogContent,
  Badge,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import {
  formatCurrency,
  formatTime,
  currencyAdornment,
} from '../../../utils/valueFormatters';
import ExpenseForm from '../expenses/ExpenseForm';
import { useState } from 'react';
import { EntityStatuses } from '../../../types/EntityStatuses';

const ClosingSheetForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    expenseTypes,
    setExpenseTypes,
    readonly = false,
    taxes,
  } = props;

  const sumExpenses = (expenses: any[]) => {
    return (
      expenses?.reduce(
        (a: number, b: any) =>
          a + (Number(b.priceGross) > 0 ? Number(b.priceGross) : 0),
        0
      ) ?? 0
    );
  };
  const [expenseDialogState, setExpenseDialogState] = useState({
    open: false,
    entity: {
      clinetId: '',
      companyId: '',
      taxTypeId: 0,
      paymentType: 'Készpénz',
      receiptNumber: '',
      paymentDeadline: new Date(),
      date: new Date(),
      isPaid: false,
      dateOfPayment: '',
      priceGross: 0,
      description: '',
      expenseTypeId: 0,
      status: EntityStatuses.SentToApproval,
    },
  });

  const expenseTypeFormat = (expenseTypeId: number) => {
    let expenseType = expenseTypes.find((et) => et.id === expenseTypeId);
    return expenseType?.name ?? '';
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        setFieldValue,
        handleChange,
        values,
        touched,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Zárólap{' '}
                {values.isClosed
                  ? values.id > 0
                    ? 'szerkesztése'
                    : 'létrehozása'
                  : 'zárása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Időpontok
                </Divider>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Zárólap dátuma"
                  value={new Date(values.date).toLocaleDateString() ?? ''}
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Munkavégzés kezdete"
                  value={new Date(values.startTime).toLocaleTimeString(
                    undefined,
                    {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    }
                  )}
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Munkavégzés vége"
                  value={
                    new Date(values.endTime).toLocaleTimeString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    }) ?? ''
                  }
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Nap kezdeti összeg
                </Divider>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={values.openBalance}
                  onChange={(e) => setFieldValue('openBalance', e.target.value)}
                  label="Induló összeg"
                  name="openBalance"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  required
                  type="number"
                  fullWidth
                  error={
                    !!touched.openBalance && !!validationErrors.openBalance
                  }
                  helperText={
                    !!touched.openBalance &&
                    !!validationErrors.openBalance &&
                    (validationErrors.openBalance as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Bevételek
                </Divider>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={values.cash}
                  label="Készpénz"
                  InputProps={{ readOnly: true }}
                  name="cash"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  type="number"
                  fullWidth
                  error={!!touched.cash && !!validationErrors.cash}
                  helperText={
                    !!touched.cash &&
                    !!validationErrors.cash &&
                    (validationErrors.cash as string)
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={values.creditCard}
                  label="Bankkártya"
                  name="creditCard"
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  type="number"
                  fullWidth
                  error={!!touched.creditCard && !!validationErrors.creditCard}
                  helperText={
                    !!touched.creditCard &&
                    !!validationErrors.creditCard &&
                    (validationErrors.creditCard as string)
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  value={values.transfer}
                  label="Utalás"
                  InputProps={{ readOnly: true }}
                  name="transfer"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  type="number"
                  error={!!touched.transfer && !!validationErrors.transfer}
                  helperText={
                    !!touched.transfer &&
                    !!validationErrors.transfer &&
                    (validationErrors.transfer as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Elszámolás
                </Divider>
              </Grid>
              <Grid container justifyContent="right">
                <Grid item>
                  <Button
                    disabled={readonly}
                    variant="outlined"
                    onClick={() => {
                      setExpenseDialogState({
                        open: true,
                        entity: {
                          clinetId: '',
                          companyId: '',
                          taxTypeId: 0,
                          paymentType: 'Készpénz',
                          receiptNumber: '',
                          paymentDeadline: new Date(),
                          date: new Date(),
                          isPaid: false,
                          dateOfPayment: '',
                          priceGross: 0,
                          description: '',
                          expenseTypeId: 0,
                          status: EntityStatuses.SentToApproval,
                        },
                      });
                    }}
                  >
                    Hozzáadás
                  </Button>
                </Grid>
                {values?.expenses?.length === 0 && (
                  <Grid item container xs={12}>
                    Nem volt kiadás.
                  </Grid>
                )}
                <Grid item container xs={12}>
                  {values?.expenses?.map((x, i) => {
                    return (
                      <Grid
                        item
                        container
                        spacing={2}
                        pt={2}
                        pb={1}
                        key={i}
                        alignItems="center"
                        textAlign={'center'}
                        style={{ borderBottom: '1px solid' }}
                      >
                        <Grid item xs={12} md={2}>
                          <TextField
                            value={expenseTypeFormat(x.expenseTypeId)}
                            variant="standard"
                            label="Kiadás típusa"
                            type="text"
                            name="receiptNumber"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            value={x.priceGross ?? 0}
                            variant="standard"
                            label="Összeg"
                            type="number"
                            name="amount"
                            fullWidth
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Ft
                                </InputAdornment>
                              ),
                            }}
                            sx={{ input: { textAlign: 'right' } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            value={x.receiptNumber}
                            variant="standard"
                            label="Bizonylatszám"
                            type="text"
                            name="receiptNumber"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            value={x.paymentType}
                            variant="standard"
                            label="Fizetési mód"
                            type="text"
                            name="receiptNumber"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            value={x.description}
                            variant="standard"
                            label="Leírás"
                            type="text"
                            name="expenseName"
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          sx={{ position: 'relative', right: { xs: '0%' } }}
                        >
                          <IconButton
                            disabled={readonly}
                            onClick={() => {
                              setFieldValue('expenses', [
                                ...values.expenses?.filter(
                                  (y) => y.receiptNumber !== x.receiptNumber
                                ),
                              ]);
                            }}
                            color={'primary'}
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} pb={2} pt={2}>
                  <Typography fontWeight={'Bold'}>Összes kiadás:</Typography>

                  {formatCurrency(sumExpenses(values?.expenses))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={formatCurrency(
                    (+values.openBalance || 0) +
                      (+values.cash || 0) -
                      (+sumExpenses(values?.expenses) || 0)
                  )}
                  label="Asztal"
                  name="desk"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Termékek
                </Divider>
              </Grid>
              {values.workItems
                ?.map((x) => x.itemPackageItems)
                ?.flat()
                .concat(values.workItems?.map((x) => x.selectedItems).flat())
                ?.map((x, i) => {
                  return (
                    <Grid
                      item
                      container
                      spacing={2}
                      pt={1}
                      key={i}
                      alignItems="center"
                      textAlign={'center'}
                      style={{ borderBottom: '1px solid' }}
                    >
                      <Grid item xs={12} md={2}>
                        <TextField
                          value={x.itemName}
                          variant="standard"
                          onChange={(e) => {
                            var value = e.target.value;
                            setFieldValue(
                              `selectedItems[${i}].itemName`,
                              value
                            );
                          }}
                          label="Termék megnevezése"
                          type="text"
                          name="itemName"
                          fullWidth
                          disabled={x.itemId > 0}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          value={
                            taxes.find((y) => y.id === x.taxTypeId)?.name ?? ''
                          }
                          variant="standard"
                          label="Adótípus"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} md={3} container>
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={x.sellPriceNet?.toFixed(2) ?? ''}
                            variant="standard"
                            label="Nettó egységár"
                            onChange={(e) => {
                              let value = parseFloat(e.target.value);
                              setFieldValue(
                                `selectedItems[${i}].sellPriceNet`,
                                value
                              );
                              let gross =
                                value *
                                (1 +
                                  (taxes?.find((y) => y.id === x.taxTypeId)
                                    ?.value ?? 27) /
                                    100);
                              setFieldValue(
                                `selectedItems[${i}].sellPriceGross`,
                                gross
                              );
                            }}
                            type="number"
                            fullWidth
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {' '}
                                  {currencyAdornment(values.currency)}
                                </InputAdornment>
                              ),
                            }}
                            sx={{ input: { textAlign: 'right' } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            value={x.sellPriceGross?.toFixed(2) ?? ''}
                            variant="standard"
                            onChange={(e) => {
                              var value = parseFloat(e.target.value);
                              setFieldValue(
                                `selectedItems[${i}].sellPriceGross`,
                                value
                              );
                              var net =
                                value /
                                (1 +
                                  taxes.find((y) => y.id === x.taxTypeId)
                                    ?.value /
                                    100);
                              setFieldValue(
                                `selectedItems[${i}].sellPriceNet`,
                                net
                              );
                            }}
                            label="Bruttó egységár"
                            type="number"
                            name="gross"
                            fullWidth
                            disabled
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {' '}
                                  {currencyAdornment(values.currency)}
                                </InputAdornment>
                              ),
                            }}
                            sx={{ input: { textAlign: 'right' } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        container
                        justifyContent="center"
                        spacing={2}
                      >
                        <Grid item xs={12} md={3}>
                          <TextField
                            value={x.amount}
                            variant="standard"
                            onChange={(e) => {
                              var value = parseFloat(e.target.value);
                              setFieldValue(
                                `selectedItems[${i}].amount`,
                                value
                              );
                            }}
                            label="Mennyiség"
                            type="number"
                            name="amount"
                            disabled
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  db
                                </InputAdornment>
                              ),
                            }}
                            sx={{ input: { textAlign: 'right' } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            value={x.discount}
                            variant="standard"
                            onChange={(e) => {
                              var value = parseFloat(e.target.value);
                              if (value >= 0 && value <= 100) {
                                setFieldValue(
                                  `selectedItems[${i}].discount`,
                                  value
                                );
                              }
                            }}
                            label="Engedmény"
                            type="number"
                            disabled
                            name="discount"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            sx={{ input: { textAlign: 'right' } }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              <Grid item xs={12}>
                <Divider textAlign="left" sx={{ mt: 2 }}>
                  Megjegyzés
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.comment}
                  onChange={handleChange}
                  label="Megjegyzés"
                  name="comment"
                  multiline
                  minRows={4}
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.comment && !!validationErrors.comment}
                  helperText={
                    !!touched.comment &&
                    !!validationErrors.comment &&
                    (validationErrors.comment as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {values.isClosed ? 'Mentés' : 'Zárás'}
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Dialog
              open={expenseDialogState.open}
              onClose={() =>
                setExpenseDialogState({ ...expenseDialogState, open: false })
              }
            >
              <DialogContent style={{ padding: '0px' }}>
                <ExpenseForm
                  entity={expenseDialogState.entity}
                  readonly={readonly}
                  isClosingSheet={true}
                  taxes={taxes}
                  onSubmit={(expense) => {
                    if (values.expenses) {
                      setFieldValue('expenses', [...values.expenses, expense]);
                    } else {
                      setFieldValue('expenses', [expense]);
                    }
                    setExpenseDialogState({
                      ...expenseDialogState,
                      open: false,
                    });
                  }}
                  onNewExpenseTypeAdded={(expenseType) => {
                    setExpenseTypes([...expenseTypes, expenseType]);
                  }}
                  errors={errors}
                  handleCancel={() =>
                    setExpenseDialogState({
                      ...expenseDialogState,
                      open: false,
                    })
                  }
                  expenseTypes={expenseTypes}
                />{' '}
              </DialogContent>
            </Dialog>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ClosingSheetForm;
