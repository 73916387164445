import { Add, Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  SpeedDial,
  TextField,
  Typography,
  useMediaQuery,
  Badge,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBarcodeScanner } from '../../../../components/barcode-scanner/useBarcodeScanner';
import useCheckPermission from '../../../../hooks/useCheckPermission';
import {
  formatCurrency,
  currencyAdornment,
} from '../../../../utils/valueFormatters';
import AddnewItemPackageDialog from '../../../sales/AddnewItemPackageDialog';
import EditItemPackageDialog from '../../../sales/EditItemPackageDialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const SelectItemPackages = (props: any) => {
  const {
    values,
    setFieldValue,
    listPrices,
    readOnly,
    services,
    loadItemPrices,
    isOffer,
    priceCategories,
    taxes,
  } = props;
  const [addItemDialog, setAddItemDialog] = useState({
    open: false,
    item: {},
  });
  const userWarehouse = useSelector((state: any) => state.userWarehouse);
  const [qrCodeReaderDialogState, setQrCodeReaderDialogState] = useState<any>({
    open: false,
  });
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const [addItemPackageDialogOpen, setAddItemPackageDialogOpen] =
    useState<boolean>(false);
  const [addItemPackageDialogId, setAddItemPackageDialogId] =
    useState<number>(0);
  const [editItemPackageDialog, setEditItemPackageDialog] = useState<any>({
    open: false,
    row: {
      id: 0,
      name: 'Termékcsomag ' + 0,
      itemNumber: '',
      amount: 1,
      discount: 0,
      sellPriceGross: 0,
      sellPriceNet: 0,
      taxTypeId: 1,
      comment: '',
      items: [],
      services: [],
    },
  });

  useEffect(() => {
    if (values.priceCategoryId !== null) {
      if (values.warehouseId) {
        loadItemPrices(values.warehouseId, values.priceCategoryId);
      } else {
        loadItemPrices(null, values.priceCategoryId);
      }
    }
  }, [values.warehouseId, values.priceCategoryId]);

  const procurementQuantityCalc = (item: any) => {
    if (item.itemId === 0) return 0;
    if (item.itemId < 0) {
      return item.amount;
    } else if (getAvailableAmount(item.itemId) < 0) {
      return getAvailableAmount(item.itemId) * -1;
    } else {
      return 0;
    }
  };

  const handleBarcodeRead = (barcode) => {
    if (readOnly) return;
    if (barcode.item) {
      setAddItemDialog({ open: true, item: barcode.item });
    } else {
      enqueueSnackbar('Ismeretlen árucikk', {
        variant: 'error',
      });
    }
  };

  useBarcodeScanner(handleBarcodeRead, listPrices);

  const getAvailableAmount = (itemId: number) => {
    let warehouseAmount = 0;
    let found = listPrices?.find((x) => x.itemId === itemId);
    if (found) {
      if (found.isComplexItem) {
        let partCount = found.parts?.map((x) => {
          let available = getAvailableAmount(x.partItemId);

          return { count: Math.floor(available / x.partAmount) };
        }) ?? [{ count: 0 }];
        if (partCount.length > 0) {
          let minCount = Math.min(...partCount.map((x) => x.count));
          warehouseAmount = minCount;
        }
      } else {
        warehouseAmount = found.availableAmount;
      }
    }
    let cartAmount = 0;

    if (!found?.isComplexItem) {
      let complexItemsInCart = values.itemPackages.filter(
        (x) => listPrices?.find((y) => x.itemId === y.itemId)?.isComplexItem
      );
      if (complexItemsInCart.length > 0) {
        complexItemsInCart.forEach((complexItem) => {
          let complexItemParts = listPrices.find(
            (x) => x.itemId === complexItem.itemId
          )?.parts;
          complexItemParts?.forEach((part) => {
            if (part.partItemId === itemId) {
              cartAmount += part.partAmount * complexItem.amount;
            }
          });
        });
      }
      cartAmount += values.selectedItems
        ?.filter((x) => x.itemId === itemId)
        ?.reduce((acc, cur) => (acc += cur.amount), 0);
      cartAmount += values.itemPackages
        ?.map((c) =>
          c.items
            ?.filter((i) => i.itemId === itemId)
            ?.reduce((acc, cur) => (acc += cur.amount), 0)
        )
        ?.reduce((acc, cur) => (acc += cur), 0);
    }

    return warehouseAmount - cartAmount;
  };

  return (
    <Grid container justifyContent="right">
      <Grid container item xs={12} spacing={2}>
        {!isOffer &&
          (userWarehouse?.warehouses.length > 1 ||
            userWarehouse?.selectedWarehouseId !== values?.warehouseId) &&
          values?.roleName === 'Dispatcher' && (
            <Grid item xs={12} md={2} pb={2}>
              <Autocomplete
                disablePortal
                fullWidth
                disabled={readOnly}
                id="warehouseId"
                value={values?.warehouseId ?? ''}
                onChange={(event, value) => {
                  setFieldValue('warehouseId', value);
                }}
                getOptionLabel={(option) => {
                  return userWarehouse?.warehouses?.find((x) => x.id === option)
                    ?.name;
                }}
                options={userWarehouse?.warehouses?.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Raktár" />
                )}
              />
            </Grid>
          )}
        {!isOffer && priceCategories?.length > 1 && (
          <Grid item xs={12} md={2} pb={2}>
            <Autocomplete
              disablePortal
              id="priceCategoryId"
              disabled={readOnly}
              value={values?.priceCategoryId ?? ''}
              onChange={(event, value) => {
                setFieldValue('priceCategoryId', value);
              }}
              getOptionLabel={(option) => {
                let priceCategory = priceCategories.find(
                  (g) => g.id === option
                );
                return priceCategory?.name ?? 'Nincs hozzáférésed';
              }}
              options={priceCategories?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Árkategória" />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid item sx={{ display: { xs: isOffer ? '' : 'none', md: 'flex' } }}>
        <Button
          disabled={readOnly}
          variant="outlined"
          onClick={() => {
            setAddItemPackageDialogOpen(true);
            let id = values.itemPackages ? values.itemPackages.length : 0;
            setAddItemPackageDialogId(id + 1);
          }}
        >
          Hozzáadás
        </Button>
      </Grid>
      {values.itemPackages?.length === 0 && (
        <Grid item container xs={12}>
          Nem igényel anyagokat
        </Grid>
      )}
      <Grid item container xs={12}>
        {values.itemPackages?.map((x, i) => {
          return (
            <Grid
              item
              container
              spacing={2}
              pt={1}
              key={i}
              alignItems="center"
              textAlign={'center'}
              style={{ borderBottom: '1px solid' }}
            >
              <Grid item xs={12} md={2}>
                <TextField
                  value={x.name}
                  variant="standard"
                  onChange={(e) => {
                    var value = e.target.value;
                    setFieldValue(`itemPackages[${i}].name`, value);
                  }}
                  label="Termék megnevezése"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  name="itemName"
                  fullWidth
                  disabled={x.itemId > 0 || readOnly}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  value={taxes.find((y) => y.id === x.taxTypeId)?.name ?? ''}
                  variant="standard"
                  label="Adótípus"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3} container>
                <Grid item xs={12} md={6}>
                  <TextField
                    value={x.sellPriceNet.toFixed(2) ?? ''}
                    variant="standard"
                    label="Nettó egységár"
                    onChange={(e) => {
                      let value = parseFloat(e.target.value);
                      setFieldValue(`itemPackages[${i}].sellPriceNet`, value);
                      let gross =
                        value *
                        (1 +
                          (taxes?.find((y) => y.id === x.taxTypeId)?.value ??
                            27) /
                            100);
                      setFieldValue(`itemPackages[${i}].sellPriceGross`, gross);
                    }}
                    type="number"
                    fullWidth
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {' '}
                          {currencyAdornment(values.currency)}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    value={x.sellPriceGross?.toFixed(2) ?? ''}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(`itemPackages[${i}].sellPriceGross`, value);
                      var net =
                        value /
                        (1 +
                          taxes.find((y) => y.id === x.taxTypeId)?.value / 100);
                      setFieldValue(`itemPackages[${i}].sellPriceNet`, net);
                    }}
                    label="Bruttó egységár"
                    type="number"
                    name="gross"
                    fullWidth
                    disabled
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {' '}
                          {currencyAdornment(values.currency)}
                        </InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} md={3}>
                  <TextField
                    value={x.amount}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(`itemPackages[${i}].amount`, value);
                    }}
                    label="Mennyiség"
                    type="number"
                    name="amount"
                    disabled={readOnly}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">db</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    value={x.discount}
                    variant="standard"
                    onChange={(e) => {
                      var value = parseFloat(e.target.value);
                      setFieldValue(`itemPackages[${i}].discount`, value);
                    }}
                    label="Engedmény"
                    type="number"
                    disabled={readOnly}
                    name="discount"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={procurementQuantityCalc(x)}
                    variant="standard"
                    label="Beszerezendő mennyiség"
                    type="number"
                    disabled={true}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">db</InputAdornment>
                      ),
                    }}
                    sx={{ input: { textAlign: 'right' } }}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight={'Bold'}>Összesen:</Typography>

                    {formatCurrency(
                      x.sellPriceGross * x.amount * (1 - x.discount / 100),
                      values.currency
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                md={0.5}
                sx={{ position: 'relative', right: { xs: '0%' } }}
              >
                <Badge
                  badgeContent={
                    (x.services?.length ?? 0) + (x.items?.length ?? 0)
                  }
                  sx={{
                    padding: '0 4px',
                    '& .MuiBadge-badge': {
                      fontSize: 13,
                      height: 15,
                      minWidth: 15,
                      top: 5,
                    },
                  }}
                  color="error"
                >
                  <IconButton
                    onClick={() => {
                      setEditItemPackageDialog({ open: true, row: x });
                    }}
                    color={'primary'}
                  >
                    <EditIcon />
                  </IconButton>{' '}
                </Badge>
              </Grid>
              <Grid
                item
                xs={6}
                md={0.5}
                sx={{ position: 'relative', right: { xs: '0%' } }}
              >
                <IconButton
                  disabled={readOnly}
                  onClick={() => {
                    setFieldValue('itemPackages', [
                      ...values.itemPackages?.filter((y) => y.id !== x.id),
                    ]);
                  }}
                  color={'primary'}
                >
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={x.comment}
                  variant="standard"
                  fullWidth
                  onChange={(e) => {
                    var value = e.target.value;
                    setFieldValue(`itemPackages[${i}].comment`, value);
                  }}
                  disabled={readOnly}
                  label="Megjegyzés"
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <AddnewItemPackageDialog
        open={addItemPackageDialogOpen}
        setOpen={setAddItemPackageDialogOpen}
        getAvailableAmount={getAvailableAmount}
        items={listPrices}
        currency={values.currency}
        conversionRate={values.conversionRate}
        id={addItemPackageDialogId}
        cart={values.itemPackages?.items ?? []}
        itemPackages={values.itemPackages ?? []}
        taxes={taxes}
        allServices={services}
        discount={values.discount ?? 0}
        selectUnpriced={isOffer}
        addToCart={(itemPackage: any) => {
          setFieldValue('itemPackages', [
            ...(values.itemPackages ?? []),
            itemPackage,
          ]);
          setAddItemPackageDialogOpen(false);
        }}
        canOverrideGrossPrice={checkPermission(['CreateOrderWithCustomPrice'])}
      />
      <EditItemPackageDialog
        open={editItemPackageDialog.open}
        setOpen={setEditItemPackageDialog}
        getAvailableAmount={getAvailableAmount}
        items={listPrices}
        cart={values.itemPackages?.items ?? []}
        itemPackages={values.itemPackages}
        currency={values.currency}
        conversionRate={values.conversionRate}
        taxes={taxes}
        readonly={readOnly}
        row={editItemPackageDialog.row}
        selectUnpriced={isOffer}
        allServices={services}
        discount={values.discount ?? 0}
        addToCart={(itemPackage: any) => {
          setFieldValue('itemPackages', [
            ...values.itemPackages?.map((x) =>
              x.id === itemPackage.id ? itemPackage : x
            ),
          ]);
          setEditItemPackageDialog({
            open: false,
            row: {
              id: 0,
              name: 'Termékcsomag ' + 0,
              itemNumber: '',
              amount: 1,
              discount: 0,
              sellPriceGross: 0,
              sellPriceNet: 0,
              taxTypeId: 1,
              comment: '',
              items: [],
              services: [],
            },
          });
        }}
        canOverrideGrossPrice={checkPermission(['CreateOrderWithCustomPrice'])}
      />
      <SpeedDial
        ariaLabel="Termék hozzáadása"
        onClick={() => {
          setAddItemPackageDialogOpen(true);
          let id = values.itemPackages ? values.itemPackages.length : 0;
          setAddItemPackageDialogId(id + 1);
        }}
        sx={{
          display: { xs: isOffer ? 'none' : '', md: 'none' },
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        icon={<Add />}
      ></SpeedDial>
    </Grid>
  );
};

export default SelectItemPackages;
