import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import useCheckPermission from '../../../hooks/useCheckPermission';
import clientService from '../../../services/crm/clientService';
import ClientAddressCreate from './ClientAddressCreate';
import ClientCreate from './ClientCreate';
import { set } from 'date-fns';
import { Text, View } from '@react-pdf/renderer';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import { Dangerous, Warning } from '@mui/icons-material';

const SelectClient = (props: any) => {
  const {
    values,
    setFieldValue,
    readonly,
    touched,
    validationErrors,
    onClientChange,
    clients,
    onClientAdded,
    onAddressAdded,
    representative,
    setRepresentative,
    isOffer = false,
    showContactInName = true,
    hideRepresentative = false,
    onWorkAddressSelected = () => {},
  } = props;

  const findClient = useCallback(
    (clientId) => {
      return clients?.find((g) => g.id === clientId);
    },
    [clients]
  );
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isRepresentative, setIsRepresentative] = useState(false);
  const isRepresentativeRef = useRef<HTMLButtonElement>();

  const { enqueueSnackbar } = useSnackbar();

  const addressesCallback = useCallback(
    () => clients.find((x) => x.id === values.clientId)?.addresses ?? [],
    [clients, values.clientId]
  );

  const addresses = addressesCallback();
  const client = findClient(values.clientId);

  useEffect(() => {
    if (values.clientId > 0) {
      clientService.get(values.clientId).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      });
    }
  }, [values.clientId]);

  useEffect(() => {
    let workAddress = addresses?.find((x) => x.id === values.workAddressId);
    if (workAddress && workAddress?.hasPerson) {
      setEmail(workAddress.personEmail ?? '');
      setPhone(workAddress.personPhone ?? '');
    } else {
      setEmail(findClient(values?.clientId)?.contact.email ?? '');
      setPhone(findClient(values?.clientId)?.contact.phone ?? '');
    }

    setIsRepresentative(false);
    isRepresentativeRef.current.value = 'false';
  }, [findClient, values]);

  useEffect(() => {
    // select workAddressId if only one address is available
    if (addresses?.length === 1 && values.workAddressId === null) {
      setFieldValue('workAddressId', addresses[0].id);
      setFieldValue('billingAddressId', addresses[0].id);
    }
    if (addresses?.length > 1 && values.billingAddress === null) {
      var billingAddress = addresses?.find((a) => a.isBillingAddress);
      if (!billingAddress) return;
      setFieldValue('workAddressId', billingAddress.id);
      setFieldValue('billingAddressId', billingAddress.id);
    }
  }, [addresses, setFieldValue, values]);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (!values.clientId) return;
    if (!addresses?.length) return;
    if (values.workAddressId) return;
    if (values.billingAddressId) return;
    var billingAddress = addresses?.find((a) => a.isBillingAddress);
    if (billingAddress) setFieldValue('billingAddressId', billingAddress.id);
    var workAddress = addresses[0];
    if (workAddress) {
      setFieldValue('workAddressId', workAddress.id);
      onWorkAddressSelected(workAddress.id);
    }
  }, [addresses]);

  const clientHasBillingAddress = useCallback(() => {
    return addresses?.some((x) => x.isBillingAddress);
  }, [addresses]);

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={11}>
        <CreateEntityDecorator
          hideAdd={readonly || !checkPermission(['ClientCreate'])}
          isClientCreate
          AutocompleteComponent={
            <Autocomplete
              autoHighlight
              disablePortal
              autoSelect
              autoComplete
              id="clientId"
              disabled={readonly}
              value={values?.clientId}
              onChange={(event, value) => {
                setFieldValue('clientId', value);
                setFieldValue('workAddressId', null);
                setFieldValue('billingAddressId', null);
                onClientChange(value);
              }}
              getOptionLabel={(option) => {
                var client = findClient(option);
                if (!client) return '';
                if (client?.companyName && client?.companyName !== '') {
                  return `${client?.companyName}(${client?.taxNumber} ${
                    showContactInName
                      ? `,${client?.contactEmail}, ${client?.contactPhone}`
                      : ''
                  }, ${client.id})`;
                } else if (client?.name && client.name.fullName !== '') {
                  return client?.name.fullName + ' (' + client.id + ')';
                }
              }}
              options={clients?.map((g) => g.id)}
              filterOptions={(options, params) => {
                // Convert the search term to lowercase for case-insensitive search
                const searchTermLower = params.inputValue.toLowerCase();

                // Perform a fuzzy search by splitting the search term into tokens
                const searchTokens = searchTermLower.split(/\s+/);

                // Filter items based on the search tokens
                const filteredItems = clients.filter((client) => {
                  // Convert the item name to lowercase
                  let name = '';
                  if (client?.companyName && client?.companyName !== '') {
                    name = `${client?.companyName}(${client?.taxNumber} ${
                      showContactInName
                        ? `,${client?.contactEmail}, ${client?.contactPhone}`
                        : ''
                    }, ${client.id})`;
                  } else if (client?.name && client.name.fullName !== '') {
                    name = client?.name.fullName + ' (' + client.id + ')';
                  }
                  const itemNameLower = name.toLowerCase();

                  // Check if each token is present in the item name
                  return searchTokens.every((token) =>
                    itemNameLower.includes(token)
                  );
                });

                return filteredItems.map((client) => client.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ügyfél"
                  required
                  fullWidth
                  error={!!touched?.clientId && !!validationErrors?.clientId}
                  helperText={
                    !!touched?.clientId &&
                    !!validationErrors?.clientId &&
                    (validationErrors?.clientId as string)
                  }
                />
              )}
            />
          }
          onSave={(client) => {
            onClientAdded(client);
            setFieldValue('clientId', client.id);
            setFieldValue('workAddressId', client.addresses[0].id);
            setFieldValue(
              'billingAddressId',
              client.addresses?.find((a) => a.isBillingAddress)?.id ?? 0
            );
            onClientChange(client.id);
          }}
          CreateEntityComponent={ClientCreate}
        />
      </Grid>
      <Grid item>
        <EntityNavigator
          entityType={EntityTypes.ClientEdit}
          entityId={values.clientId}
          value={readonly ? 'Megtekintés' : 'Szerkesztés'}
          showIcon
          readonly={readonly || !(values.clientId > 0)}
          onSave={(client) => {
            onClientAdded(client);
          }}
        />
      </Grid>
      {client?.isOfferMandatory && (
        <Grid item>
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line', fontSize: '20px' }}>
                {'Új ügyfélnek kötelező árajánlatot készíteni!'}
              </div>
            }
            children={<Warning color="error" />}
          />
        </Grid>
      )}
      {client?.hasSpecialNeeds && (
        <Grid item>
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line', fontSize: '20px' }}>
                {`Az ügyfélnek különleges igényei vannak!\n${client?.specialNeeds}`}
              </div>
            }
            children={<Warning color="error" />}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <CreateEntityDecorator
          hideAdd={readonly || !checkPermission(['ClientEdit'])}
          AutocompleteComponent={
            <Autocomplete
              disablePortal
              disabled={readonly}
              id="workAddressId"
              value={values?.workAddressId ?? ''}
              onChange={(event, value) => {
                setFieldValue('workAddressId', value);
                onWorkAddressSelected(value);
              }}
              getOptionLabel={(option) =>
                addresses?.find((g) => g.id === option)?.fullAddress ?? ''
              }
              options={addresses?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Munkavégzés helyszíne"
                  required
                  fullWidth
                  error={
                    !!touched?.workAddressId &&
                    !!validationErrors?.workAddressId
                  }
                  helperText={
                    !!touched?.workAddressId &&
                    !!validationErrors?.workAddressId &&
                    (validationErrors?.workAddressId as string)
                  }
                />
              )}
            />
          }
          onSave={(address) => {
            onAddressAdded(address, values.clientId);
            setFieldValue('workAddressId', address.id);
          }}
          CreateEntityComponent={ClientAddressCreate}
          createEntityComponentProps={{
            clientId: values.clientId,
            hasBillingAddress: clientHasBillingAddress(),
            canEditExisting: false,
            unselectableAddresses: addresses.map((x) => x.id),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CreateEntityDecorator
          hideAdd={
            readonly ||
            !checkPermission(['ClientEdit']) ||
            clientHasBillingAddress()
          }
          AutocompleteComponent={
            <Autocomplete
              disablePortal
              disabled={readonly}
              id="billingAddressId"
              value={values?.billingAddressId ?? ''}
              onChange={(event, value) => {
                setFieldValue('billingAddressId', value);
              }}
              getOptionLabel={(option) =>
                addresses?.find((g) => g.id === option)?.fullAddress ?? ''
              }
              options={addresses
                .filter((x) => x.isBillingAddress)
                ?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Számlázási cím"
                  required
                  fullWidth
                  error={
                    !!touched?.billingAddressId &&
                    !!validationErrors?.billingAddressId
                  }
                  helperText={
                    !!touched?.billingAddressId &&
                    !!validationErrors?.billingAddressId &&
                    (validationErrors?.billingAddressId as string)
                  }
                />
              )}
            />
          }
          onSave={(address) => {
            onAddressAdded(address, values.clientId);
            setFieldValue('billingAddressId', address.id);
          }}
          CreateEntityComponent={ClientAddressCreate}
          createEntityComponentProps={{
            clientId: values.clientId,
            canEditExisting: false,
            unselectableAddresses: addresses.map((x) => x.id),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Munkavégzés helyszíne megjegyzése"
          value={
            addresses?.find((x) => x.id === values.workAddressId)?.comment ?? ''
          }
          multiline
          minRows={2}
          disabled
          name="workAddress"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Számlázási cím megjegyzése"
          value={
            addresses?.find((x) => x.id === values.billingAddressId)?.comment ??
            ''
          }
          multiline
          minRows={2}
          disabled
          name="billingAddressId"
        />
      </Grid>
      {checkPermission(['ClientDetailsView']) && (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={phone}
              label="Telefonszám"
              disabled={readonly}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={email}
              label="Email"
              disabled={readonly}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Ügyfél megjegyzése"
              value={client?.comment ?? ''}
              multiline
              minRows={2}
              disabled
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </>
      )}
      <Box display={hideRepresentative ? 'none' : 'block'}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                ref={isRepresentativeRef}
                checked={isRepresentative}
                disabled={readonly}
                onChange={(event) => setIsRepresentative(event.target.checked)}
              />
            }
            label="Kapcsolattartó adatai"
          />
        </Grid>
        {isRepresentative && (
          <Grid container spacing={2} pl={2}>
            <Grid item xs={12}>
              <Divider textAlign="left">Kapcsolattartó adatai</Divider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="lastName"
                disabled={readonly}
                value={representative?.representativeName?.lastName}
                onChange={(e) => {
                  setRepresentative((prev) => ({
                    ...prev,
                    representativeName: {
                      ...prev.representativeName,
                      lastName: e.target.value,
                    },
                  }));
                }}
                label="Vezetéknév"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="firstName"
                disabled={readonly}
                value={representative?.representativeName?.firstName}
                onChange={(e) =>
                  setRepresentative((prev) => ({
                    ...prev,
                    representativeName: {
                      ...prev.representativeName,
                      firstName: e.target.value,
                    },
                  }))
                }
                label="Keresztnév"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={representative?.representativePhone}
                disabled={readonly}
                name="representativePhone"
                onChange={(e) =>
                  setRepresentative((prev) => ({
                    ...prev,
                    representativePhone: e.target.value,
                  }))
                }
                label="Telefonszám"
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default memo(
  SelectClient,
  (prev, next) =>
    prev.values.billingAddressId === next.values.billingAddressId &&
    prev.values.workAddressId === next.values.workAddressId &&
    prev.values.clientId === next.values.clientId &&
    prev.values.id === next.values.id &&
    prev.clients === next.clients &&
    prev.addresses === next.addresses &&
    prev.readonly === next.readonly &&
    prev.email === next.email &&
    prev.phone === next.phone &&
    prev.isRepresentative === next.isRepresentative &&
    prev.representative === next.representative
);
