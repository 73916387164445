import {
  Add as AddIcon,
  Cached,
  Cancel,
  CheckCircle,
  Delete as DeleteIcon,
  LocalShipping,
  PauseCircleFilled,
} from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GridRowParams } from '../../../node_modules/@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../components/DataList';
import EntityNavigator from '../../components/EntityNavigator';
import FilePageDialog from '../../components/FilePageDialog';
import useCheckPermission from '../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../hooks/useFunctionDescriptor';
import acquisitionService from '../../services/acquisitions/acquisitionService';
import { AcquisitionStatuses } from '../../types/AcquisitionStatuses';
import { AttachmentTypes } from '../../types/AttachmentTypes';
import { EntityTypes } from '../../types/EntityTypes';
import { formatCurrency } from '../../utils/valueFormatters';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { CompanyAssetStates } from '../../types/InstrumentStates';
import companyAssetService from '../../services/deviceManagement/companyAssetService';
import EngineeringIcon from '@mui/icons-material/Engineering';

export interface Acquisition {
  id: number;
  clientId: number;
  clientName: string;
  unitPrice: number;
  amount: number;
}

const AcquisitionsPage = (props: any) => {
  const { externalRows } = props;
  const { form, acquisitionInProgress } = useSelector<any>(
    (state) => state.acquisition
  ) as any;
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const isNotSmallScreen = useMediaQuery('(min-width:800px)');

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const [tabValue, setTabValue] = useState(0);
  const [acquisitionBadges, setAcquisitionBadges] = useState<any[]>([]);
  const acquisitionTabLocal = useLocalStorage('acquisitionStatus');
  const [status, setStatus] = useState<AcquisitionStatuses | null>(
    AcquisitionStatuses.InProgress
  );
  const acquisitionTitleDescriptor = useFunctionDescriptor(
    'AcquisitionsPage.title'
  );

  useEffect(() => {
    if (acquisitionTabLocal.value !== null) {
      setTabValue(acquisitionTabLocal.value);
      let status = getStatusBasedOnTabValue(acquisitionTabLocal.value);
      setStatus(status);
    }
  }, [acquisitionTabLocal.value]);

  const getStatusBasedOnTabValue = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return AcquisitionStatuses.Draft;
      case 1:
        return AcquisitionStatuses.InProgress;
      case 2:
        return AcquisitionStatuses.Arrived;

      case 3:
        return AcquisitionStatuses.Completed;
      case 4:
        return AcquisitionStatuses.Paused;
      case 5:
        return AcquisitionStatuses.Cancelled;
      default:
        return null;
    }
  };

  const updateRowStatus = (row: any, status: AcquisitionStatuses) => {
    dispatch({ type: 'SHOW_QUERY' });
    acquisitionService.get(row.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        acquisitionService
          .update({ ...response, status })
          .then((response) => {
            if (response.canceled) return;
            if (response.hasError) {
              enqueueSnackbar(response.errorMessages.join(','), {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Sikeres módosítás!', {
                variant: 'success',
              });
              setRows(rows.filter((r) => r.id !== row.id));
              listBadges();
            }
          })
          .finally(() => dispatch({ type: 'HIDE' }));
      }
    });
  };

  const listBadges = () => {
    if (!externalRows) {
      acquisitionService.listBadge().then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setAcquisitionBadges(response.acquisitionBadges);
        }
      });
    } else {
      let acquisitionBadges = [
        0,
        externalRows.filter((row) => row.status === AcquisitionStatuses.Draft)
          .length,
        externalRows.filter(
          (row) => row.status === AcquisitionStatuses.InProgress
        ).length,
        externalRows.filter((row) => row.status === AcquisitionStatuses.Arrived)
          .length,
        externalRows.filter(
          (row) => row.status === AcquisitionStatuses.Completed
        ).length,
        externalRows.filter((row) => row.status === AcquisitionStatuses.Paused)
          .length,
        externalRows.filter(
          (row) => row.status === AcquisitionStatuses.Cancelled
        ).length,
      ];
      setAcquisitionBadges(acquisitionBadges);
    }
  };
  useEffect(() => {
    listBadges();
  }, []);

  useEffect(() => {
    if (externalRows) {
      setRows(
        externalRows.filter((row) =>
          status === null ? true : row.status === status
        )
      );
      return;
    }

    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    acquisitionService
      .list(status, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      abort.abort();
    };
  }, [status]);

  useEffect(() => {
    if (acquisitionInProgress && !(form.id > 0)) {
      navigate('/acquisitions/create');
    }
  }, [acquisitionInProgress, externalRows, form]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (
      checkPermission(['AcquisitionUpdateStatus']) &&
      params.row.status !== AcquisitionStatuses.Completed
    ) {
      if (params.row.status !== AcquisitionStatuses.Draft) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Tervezés alatt">
                <EngineeringIcon />
              </Tooltip>
            }
            label="Tervezés alatt"
            onClick={() =>
              updateRowStatus(params.row, AcquisitionStatuses.Draft)
            }
            showInMenu
          />
        );
      }
      if (params.row.status !== AcquisitionStatuses.InProgress) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Folyamatban">
                <Cached />
              </Tooltip>
            }
            label="Folyamatban"
            onClick={() =>
              updateRowStatus(params.row, AcquisitionStatuses.InProgress)
            }
            showInMenu
          />
        );
      }
      if (params.row.status !== AcquisitionStatuses.Arrived) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Megérkezett">
                <LocalShipping />
              </Tooltip>
            }
            label="Megérkezett"
            onClick={() =>
              updateRowStatus(params.row, AcquisitionStatuses.Arrived)
            }
            showInMenu
          />
        );
      }
      if (params.row.status !== AcquisitionStatuses.Completed) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Teljesítve">
                <CheckCircle />
              </Tooltip>
            }
            label="Teljesítve"
            onClick={() =>
              updateRowStatus(params.row, AcquisitionStatuses.Completed)
            }
            showInMenu
          />
        );
      }
      if (params.row.status !== AcquisitionStatuses.Paused) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Szüneteltetés">
                <PauseCircleFilled />
              </Tooltip>
            }
            label="Szüneteltetés"
            onClick={() =>
              updateRowStatus(params.row, AcquisitionStatuses.Paused)
            }
            showInMenu
          />
        );
      }
      if (params.row.status !== AcquisitionStatuses.Cancelled) {
        actions.push(
          <GridActionsCellItem
            color={color ? color : 'primary'}
            icon={
              <Tooltip title="Megszakítva">
                <Cancel />
              </Tooltip>
            }
            label="Megszakítva"
            onClick={() =>
              updateRowStatus(params.row, AcquisitionStatuses.Cancelled)
            }
            showInMenu
          />
        );
      }
    }

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    let readonly =
      !checkPermission(['AcquisitionEdit']) ||
      (params.row.status === AcquisitionStatuses.Completed &&
        !checkPermission(['AcquisitionUpdateAfterCompletion']));
    actions.push(
      <EntityNavigator
        entityType={EntityTypes.Acquisiton}
        entityId={params.row.id}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        readonly={readonly}
      />
    );

    if (checkPermission(['AcquisitionDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() => {
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => handleDelete(params.row.id),
            });
          }}
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    { field: 'companyName', headerName: 'Számlakibocsátó', flex: 100 },
    {
      field: 'clientNameForQuickSearch',
      headerName: 'ÜgyfélKereső',
      flex: 100,
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientName',
      headerName: 'Beszállító',
      flex: 100,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
            disableLink={externalRows ? true : false}
          />
        );
      },
    },
    {
      field: 'invoiceNumber',
      headerName: 'Számlaszám',
      flex: 100,
      editable: false,
    },
    {
      field: 'date',
      headerName: 'Beszerzés ideje',
      flex: 100,
      editable: false,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleString();
      },
    },
    {
      field: 'paymentDeadLine',
      headerName: 'Fizetési határidő',
      flex: 100,
      editable: false,
      valueFormatter: (params) => {
        return parseJSON(params.value).toLocaleString();
      },
    },
    {
      field: 'paymentType',
      headerName: 'Fizetési mód',
      flex: 100,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Megjegyzés',
      flex: 100,
      editable: false,
    },
    {
      field: 'finalPrice',
      headerName: 'Nettó összeg',
      flex: 100,
      editable: false,
      valueGetter(params) {
        return formatCurrency(params.row.finalPrice, params.row.currency);
      },
    },
    {
      field: 'status',
      headerName: 'Státusz',
      flex: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.value === AcquisitionStatuses.Draft ? (
              <span style={{ color: 'orange' }}>Tervezés alatt</span>
            ) : params.value === AcquisitionStatuses.InProgress ? (
              <span style={{ color: '#3400C4' }}>Folyamatban</span>
            ) : params.value === AcquisitionStatuses.Paused ? (
              <span style={{ color: '#0062C4' }}>Szünetelve</span>
            ) : params.value === AcquisitionStatuses.Completed ? (
              <span style={{ color: 'green' }}>Teljesítve</span>
            ) : params.value === AcquisitionStatuses.Cancelled ? (
              <span style={{ color: '#FFCC00' }}>Megszakítva</span>
            ) : params.value === AcquisitionStatuses.Arrived ? (
              <span style={{ color: 'lightgreen' }}>Megérkezett</span>
            ) : null}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    acquisitionService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Beszerzések{acquisitionTitleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(e, value) => {
                  setTabValue(value);
                  acquisitionTabLocal.setLocalStorageValue(value);
                  let status = getStatusBasedOnTabValue(value);
                  setStatus(status);
                }}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                allowScrollButtonsMobile
                sx={{
                  maxWidth: !isNotSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                scrollButtons="auto"
                variant={isNotSmallScreen ? 'fullWidth' : 'scrollable'}
              >
                <Tab
                  value={0}
                  label={
                    <Badge
                      badgeContent={
                        acquisitionBadges[AcquisitionStatuses.Draft]
                      }
                      color="primary"
                      max={9999}
                      sx={{
                        padding: '0 4px',
                        '& .MuiBadge-badge': {
                          fontSize: 13,
                          height: 15,
                          minWidth: 15,
                          top: -5,
                          left: -10,
                          right: 25,
                        },
                      }}
                    >
                      Tervezés alatt
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={1}
                  label={
                    <Badge
                      badgeContent={
                        acquisitionBadges[AcquisitionStatuses.InProgress]
                      }
                      max={9999}
                      sx={{
                        padding: '0 4px',
                        '& .MuiBadge-badge': {
                          fontSize: 13,
                          height: 15,
                          minWidth: 15,
                          top: -5,
                          left: -10,
                          right: 25,
                        },
                      }}
                      color="primary"
                    >
                      Folyamatban
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={2}
                  label={
                    <Badge
                      badgeContent={
                        acquisitionBadges[AcquisitionStatuses.Arrived]
                      }
                      max={9999}
                      sx={{
                        padding: '0 4px',
                        '& .MuiBadge-badge': {
                          fontSize: 13,
                          height: 15,
                          minWidth: 15,
                          top: -5,
                          left: -10,
                          right: 25,
                        },
                      }}
                      color="primary"
                    >
                      Megérkezett
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={3}
                  label={
                    <Badge
                      badgeContent={
                        acquisitionBadges[AcquisitionStatuses.Completed]
                      }
                      max={9999}
                      sx={{
                        padding: '0 4px',
                        '& .MuiBadge-badge': {
                          fontSize: 13,
                          height: 15,
                          minWidth: 15,
                          top: -5,
                          left: -5,
                          right: 30,
                        },
                      }}
                      color="primary"
                    >
                      Teljesítve
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={4}
                  label={
                    <Badge
                      badgeContent={
                        acquisitionBadges[AcquisitionStatuses.Paused]
                      }
                      max={9999}
                      sx={{
                        padding: '0 4px',
                        '& .MuiBadge-badge': {
                          fontSize: 13,
                          height: 15,
                          minWidth: 15,
                          top: -5,
                          left: -10,
                          right: 25,
                        },
                      }}
                      color="primary"
                    >
                      Szünetelve
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={5}
                  label={
                    <Badge
                      badgeContent={
                        acquisitionBadges[AcquisitionStatuses.Cancelled]
                      }
                      max={9999}
                      sx={{
                        padding: '0 4px',
                        '& .MuiBadge-badge': {
                          fontSize: 13,
                          height: 15,
                          minWidth: 15,
                          top: -5,
                          left: -10,
                          right: 25,
                        },
                      }}
                      color="primary"
                    >
                      Megszakítva
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
                <Tab
                  value={6}
                  label={
                    <Badge
                      badgeContent={Object.values(acquisitionBadges).reduce(
                        (sum, value) => sum + value,
                        0
                      )}
                      max={9999}
                      sx={{
                        padding: '0 4px',
                        '& .MuiBadge-badge': {
                          fontSize: 13,
                          height: 15,
                          minWidth: 15,
                          top: -5,
                          left: -10,
                          right: 25,
                        },
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      color="primary"
                    >
                      Összes
                    </Badge>
                  }
                  sx={{ fontWeight: 'bold' }}
                />
              </Tabs>
            </Box>
          </Box>
        </Grid>
        {checkPermission(['AcquisitionCreate']) && !externalRows && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <IconButton
                component={RouterLink}
                to={`/acquisitions/create`}
                color="primary"
              >
                <Tooltip title="Beszerzés indítása">
                  <AddIcon />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'AcquisitionsPage'}
          />
        </Grid>
      </Grid>
      <ConfirmDeleteDialog />;
      <FilePageDialog
        onList={(length) => {
          setRows((prev) => {
            return prev.map((row) => {
              if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                row.attachmentCount = length;
              }
              return row;
            });
          });
        }}
        open={filePageDialogOpen.open}
        onClose={() =>
          setFilePageDialogOpen({ open: false, entityUniqueId: null })
        }
        attachmentType={AttachmentTypes.Acquisition}
        entityUniqueId={filePageDialogOpen.entityUniqueId}
        localStorageKey={'AcquisitionsPage'}
      />
    </Paper>
  );
};

export default AcquisitionsPage;
