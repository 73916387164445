import {
  Add as AddIcon,
  CheckCircle as ApproveIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  OpenInNew,
  Visibility,
  Sync,
  FileUpload,
} from '@mui/icons-material';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Badge,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import AnimationIcon from '@mui/icons-material/Animation';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import { ClientWorkItemsDialog } from './ClientWorkItemsDialog';
import AssignmentIcon from '@mui/icons-material/Assignment';
import accountService from '../../../services/accountService';
import billingoService from '../../../services/billingoService';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilePageDialog from '../../../components/FilePageDialog';
import { ChimneySweepingCertificate } from '../../chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificatePage';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ClientsPage = (props: any) => {
  const { isSmartSearch = false } = props;
  const navigate = useNavigate();
  const [rows, setRows] = useState<any[]>([]);
  const [filePageDialogOpen, setFilePageDialogOpen] = useState<any>({
    open: false,
    entityUniqueId: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const { checkPermission } = useCheckPermission();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const params = useParams();
  const [clientWorkItemsDialogState, setClientWorkItemsDialogState] = useState({
    open: false,
    clientId: 0,
  });

  const [status, setStatus] = useState(
    params.approval === 'approval'
      ? EntityStatuses.SentToApproval
      : EntityStatuses.Approved
  );

  const clientTitleDescriptor = useFunctionDescriptor('ClientsPage.title');

  const [filter, setFilter] = useState('all');

  // Filter the rows based on the value of the filter state variable
  const filteredRows = rows.filter((row) => {
    if (filter === 'private') return row.isPrivatePerson;
    if (filter === 'organization') return !row.isPrivatePerson;
    if (filter === 'distributor') return row.clientType === 3;
    return true;
  });

  useEffect(() => {
    let abort = new AbortController();

    dispatch({ type: 'SHOW_QUERY' });
    clientService.list(false, status, abort.signal).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setRows(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
      dispatch({ type: 'HIDE' });
    });

    return () => {
      abort.abort();
    };
  }, [status]);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Ügyfélprofil">
            <AccountCircleIcon />
          </Tooltip>
        }
        label={'Ügyfélprofil'}
        onClick={() => navigate(`/crm/clients/profile/${params.row.id}`)}
        showInMenu
      />
    );

    if (checkPermission(['CreateFileAttachment'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Fájl feltöltése">
              <Badge
                badgeContent={params.row.attachmentCount}
                sx={{
                  padding: '0 4px',
                  '& .MuiBadge-badge': {
                    fontSize: 13,
                    height: 15,
                    minWidth: 15,
                    top: 5,
                  },
                }}
                color="error"
              >
                <AttachFileIcon />
              </Badge>
            </Tooltip>
          }
          label="Fájl feltöltése"
          onClick={() => {
            setFilePageDialogOpen({
              open: true,
              entityUniqueId: params.row.uniqueId,
            });
          }}
        />
      );
    }

    if (status === EntityStatuses.Approved) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Munkalapok">
              <AssignmentIcon />
            </Tooltip>
          }
          label="Munkalapok"
          onClick={() =>
            setClientWorkItemsDialogState({
              open: true,
              clientId: params.row.id,
            })
          }
          showInMenu
        />
      );
    }

    if (
      status === EntityStatuses.SentToApproval &&
      checkPermission(['ItemApprove'])
    ) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'success'}
          icon={
            <Tooltip title="Jóváhagyás">
              <ApproveIcon />
            </Tooltip>
          }
          label="Jóváhagyás"
          onClick={() => handleApprove(params.row.id)}
        />
      );
    }

    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Weboldal felkeresése új lapon">
            <OpenInNew />
          </Tooltip>
        }
        label="Weboldal felkeresése új lapon"
        disabled={!params.row.contact.webAddress}
        onClick={() => {
          try {
            let urlString = params.row.contact.webAddress;
            if (!urlString.startsWith('http')) {
              urlString = 'https://' + urlString;
            }

            window.open(urlString, '_blank').focus();
          } catch (error) {
            console.error('Invalid URL: ', params.row.contact.webAddress);
          }
        }}
        showInMenu
      />
    );

    let readonly = !checkPermission(['ClientEdit']);
    actions.push(
      <EntityNavigator
        entityType={EntityTypes.ClientEdit}
        entityId={params.row.id}
        value={readonly ? 'Megtekintés' : 'Szerkesztés'}
        showIcon
        readonly={readonly}
      />
    );

    if (checkPermission(['ClientDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
          showInMenu
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'isTaxNumberInvalid',
      headerName: 'Adószám érvényesség',
      flex: 20,
      renderCell(params) {
        return params.row.isTaxNumberInvalid ? (
          <Tooltip title="Érvénytelen adószám">
            <IconButton color="error">
              <DangerousIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Érvényes adószám">
            <IconButton color="success">
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
    { field: 'id', headerName: 'ID', flex: 100 },
    {
      field: 'fullName',
      headerName: 'Ügyfél',
      flex: 100,
      editable: false,
      // valueGetter: (params: any) => {
      //   return params.row.isPrivatePerson
      //     ? `${params.row.name.fullName}`
      //     : params.row.companyName;
      // },
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.id}
            value={
              params.row.isPrivatePerson
                ? `${params.row.name.fullName}`
                : params.row.companyName
            }
          />
        );
      },
    },
    {
      field: 'companyName',
      headerName: 'Cég',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.clientName ?? ''}`;
      },
    },
    {
      field: 'taxNumber',
      headerName: 'Adószám',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.taxNumber ?? ''}`;
      },
    },
    {
      field: 'contact.phone',
      headerName: 'Telefonszám',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.contact.phone ?? ''}`;
      },
    },
    {
      field: 'contact.email',
      headerName: 'Email ',
      flex: 100,
      editable: false,
      valueGetter: (params: any) => {
        return `${params.row.contact.email ?? ''}`;
      },
    },
    {
      field: 'comment',
      headerName: 'Megjegyzés ',
      flex: 100,
      editable: false,
    },
    {
      field: 'addressesForQuickSearch',
      headerName: 'Címek',
      flex: 100,
      valueGetter: (params: any) => {
        let addresses = '';
        params.row.addresses?.forEach((address: any) => {
          addresses += address.fullAddress + ';';
        });
        return addresses;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    clientService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés!', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };
  const handleApprove = (id: number) => {
    clientService.approve(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres jóváhagyás', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Ügyfelek{clientTitleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            value={filter}
            onChange={(event) => setFilter(event.target.value)}
          >
            <Grid container>
              <Grid item>
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Magánszemélyek"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="organization"
                  control={<Radio />}
                  label="Szervezetek"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="distributor"
                  control={<Radio />}
                  label="Számlakibocsátó"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="Mind"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid xs={6} item p={2}>
          Jóváhagyásra váró ügyfelek
          <Switch
            value={status}
            checked={status === EntityStatuses.SentToApproval}
            onChange={() => {
              setStatus(
                status === EntityStatuses.Approved
                  ? EntityStatuses.SentToApproval
                  : EntityStatuses.Approved
              );
            }}
          />
        </Grid>
        <Grid item></Grid>

        <Grid container item xs={6} p={2} justifyContent="end">
          {checkPermission(['ClientCombine']) && (
            <Tooltip title="Partnerek összevonása">
              <Grid item>
                <IconButton
                  onClick={() => {
                    dispatch({ type: 'SHOW_QUERY' });
                    clientService
                      .combine()
                      .then((e) => {
                        enqueueSnackbar('Sikeres összevonás!', {
                          variant: 'success',
                        });

                        setTimeout(() => {
                          clientService.list(false, status).then((response) => {
                            if (response.canceled) return;
                            if (!response.hasError) setRows(response.records);
                            else
                              enqueueSnackbar(
                                response.errorMessages.join(','),
                                {
                                  variant: 'error',
                                }
                              );
                          });
                        }, 1000);
                      })

                      .catch((e) => {
                        enqueueSnackbar('Sikertelen összevonás!', {
                          variant: 'error',
                        });
                      })
                      .finally(() => dispatch({ type: 'HIDE' }));
                  }}
                  color="primary"
                >
                  <AnimationIcon />
                </IconButton>
              </Grid>
            </Tooltip>
          )}
          {checkPermission(['BillingoInvoiceSync']) && (
            <Tooltip title="Billingo partnerek szinkronizálása">
              <Grid item>
                <IconButton
                  onClick={() => {
                    dispatch({ type: 'SHOW_QUERY' });
                    billingoService
                      .startSync()
                      .then((e) => {
                        enqueueSnackbar('Sikeres szinkronizáció!', {
                          variant: 'success',
                        });
                        clientService
                          .combine()
                          .then((e) => {
                            enqueueSnackbar('Sikeres összevonás!', {
                              variant: 'success',
                            });
                            setTimeout(() => {
                              clientService
                                .list(false, status)
                                .then((response) => {
                                  if (response.canceled) return;
                                  if (!response.hasError)
                                    setRows(response.records);
                                  else
                                    enqueueSnackbar(
                                      response.errorMessages.join(','),
                                      {
                                        variant: 'error',
                                      }
                                    );
                                })
                                .finally(() => dispatch({ type: 'HIDE' }));
                            }, 1000);
                          })

                          .catch((e) => {
                            enqueueSnackbar('Sikertelen összevonás!', {
                              variant: 'error',
                            });
                          });
                      })
                      .catch((e) => {
                        enqueueSnackbar('Sikertelen szinkronizáció!', {
                          variant: 'error',
                        });
                      });
                  }}
                  color="primary"
                >
                  <Sync />
                </IconButton>
              </Grid>
            </Tooltip>
          )}
          {checkPermission(['ClientCreateDraft']) && (
            <Grid item>
              <Tooltip title="Ügyfél létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/crm/clients/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <DataList
            rows={filteredRows}
            columns={columns}
            localStorageKey={'clients'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <FilePageDialog
          onList={(length) => {
            setRows((prev) => {
              return prev.map((row) => {
                if (row.uniqueId === filePageDialogOpen.entityUniqueId) {
                  row.attachmentCount = length;
                }
                return row;
              });
            });
          }}
          open={filePageDialogOpen.open}
          onClose={() =>
            setFilePageDialogOpen({ open: false, entityUniqueId: null })
          }
          attachmentType={AttachmentTypes.Client}
          entityUniqueId={filePageDialogOpen.entityUniqueId}
          localStorageKey={'ClientsPage'}
        />
      </Grid>
      <ClientWorkItemsDialog
        clientId={clientWorkItemsDialogState.clientId}
        onClose={() =>
          setClientWorkItemsDialogState({ open: false, clientId: 0 })
        }
        open={clientWorkItemsDialogState.open}
      />
    </Paper>
  );
};

export default ClientsPage;
